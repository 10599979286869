.chart{
    width: 100%;
    display: flex;   
    justify-content: flex-start;
    flex-wrap: wrap;
}

.chart>div{   
    width: 48%;
    align-self: center;
    margin-right: 1%;
    margin-top: 20px;
    
}

.chart-input{
    display: flex;
}

.chart-input>div{
    margin-right: 10px;
}
