.Input{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.Input>div{
    flex-basis: 49%;
    margin-bottom: 10px;
}


