.content-box{
   border:  solid 1px rgba(145, 158, 171, 0.32);
   margin-bottom: 10px;
   border-radius: 8px;
   padding: 10px;
}

.content-input-box{
    width: 100%;
    display: flex;   
    justify-content: flex-start;
    flex-wrap: wrap;
    
}

.content-input-box>div{
    width: 100%;
    align-self: center;  
}

.content-input-box>div.horizontal:not(.content-editor){     
    width: 30%; 
}

.content-input-box>div.horizontal.content-editor{
    width: 70%;
}
