.EmailCategory{
    margin: 10px; 
    min-height: 100px;   
    display: flex;
    justify-content: space-between;
}

.EmailCategory>div{
    flex-basis: 48%;
}

.EmailCategory>div:first-child{
    text-align: center;
    padding: 30px;
}

.EmailCategory>div:last-child>div{
    border: solid 1px rgba(145, 158, 171, 0.32);
}

.SubItems>div,
.AccordionContainer>button,
.SubItems>button{
    text-align: center;
    border-radius: 8px;
    margin-top: 10px;
    width: 100%;
    border: solid 1px rgba(145, 158, 171, 0.32);
}

.TextAndButton{
    display: flex;
    align-items: center;
    width: 100%;
    margin: -10px 0;
}

.TextAndButton>div:first-child {
    margin-right: auto;
    visibility: hidden;
    width: 83px;
}
.TextAndButton>button:nth-child(3){
    margin-left: auto;
}

