.fixed-menu{
    padding: .5px;
    position: fixed;
    top: 29%;
    right: 2%;
    z-index: 99999;
    box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%);
    border-radius: 12px;
    background-color: #fff;
}

.MuiToggleButtonGroup-grouped {
    margin: 3px;
    border: 0;    
}

.MuiToggleButtonGroup-grouped.Mui-disabled{
    border: 0,
}

.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-radius: 10px;
}

.MuiToggleButtonGroup-grouped:first-of-type {
    border-radius: 10px;
}

.fixed-menu>.MuiIconButton-sizeMedium {
    padding: 4.3px 0.1px;
    border-radius: 30%;
    margin-right: 3px;
}
