.symbol-dialog{
   display: block;
   top: 0;
   left: 0;
   margin: 105px 26%;
   width: 50%;
   background-color: #e3e3e3;
   padding: 20px;
   border-radius: 30px;
   box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%);
}

.icon-symbol{
  border: 1px solid black !important;
  border-radius: 5px !important;
  margin: 2px auto !important;
  line-height: 1px !important;
  background: white !important;
  width: 60px !important;
  font-size: 30px;
  height: 60px !important;  
}

.symbol-wrapper{
    display: flex;
    flex-wrap: wrap;

}

.symbol-wrapper div{
  flex-basis: 100%;
  text-align: center;
  padding: 10px;
}

.symbol-container{
  overflow: auto;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}