.mainSection,
.about1Section,
.about2Section,
.otherSections{
    display: flex;
    flex-wrap: wrap;
    border: solid 1px rgba(145, 158, 171, 0.32);
    border-radius: 10px;
    padding: 20px;
    margin-bottom:20px;
}

.mainSection>div{
    flex-basis: 40%;
    margin: auto;
    margin-top: 0;
}

.mainSection>div:first-child{
    flex-basis: 15%;
}



.about1Section>div{
    flex-basis: 30%;  
    margin: auto;
}

.about1Section>label{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.about2Section>div{
    flex-basis: 70%;    
}

.about2Section>div:first-child{
    flex-basis: 30%;
    display: flex;
    flex-direction: column;    
}

.about2Section>div:first-child>label{    
    display: flex;
    justify-content: center;
    margin: auto;
}
.about2Section>div:first-child>div{ 
    margin: auto;
}

.otherSections>div{
    flex-basis: 40%;
    margin: auto;    
}

.otherSections label{
    margin: 10px auto;
    
}