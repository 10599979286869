.Container{
    display: flex;
    justify-content: space-between;
}

.Inputs{
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    margin:0;
}

.Others{
    flex-basis: 58%;
}

.Inputs>div:nth-child(3){
    margin-bottom: auto;
}

.Container>div>div{
    margin-bottom: 10px;
}

.Container>div>div{
    margin-bottom: 10px;
}

.Others>div:first-child{
    display: flex;
}

.Others>div:first-child>div{
    margin: auto;
}